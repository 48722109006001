a {
  color: var(--primary);
  transition-property: color;
  overflow-wrap: break-word;
}

a:hover {
  color: var(--text);
}

a:not([href]) {
  color: var(--text);
}
